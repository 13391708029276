<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 474.15 398.84"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M449.44,299.74c0-10.86.5-21-.1-31.15-1.43-24.09-13.1-42.54-32.67-56.15-14.75-10.27-31.37-13.29-49-11.79-29.09,2.47-49,18-61.51,43.75-5.28,10.86-6.39,22.64-6.34,34.57,0,6.76,0,13.53,0,20.89H275.62v99H474.15v-99.1Zm-50.19-.41H350.47c0-8.83-.48-17.34.1-25.77,1.29-18.69,18.9-29.61,34.86-21.68,8.73,4.35,13.32,12,13.74,21.68C399.54,282,399.25,290.5,399.25,299.33Z"/><path d="M449.57,0H.1V49.7H449.57Z"/><path d="M299.41,125.52H0V173.7H299.41Z"/><path d="M0,299.44H149.33V249.56H0Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconBankAccountName',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
